<template>
  <div class="w-full px-4 py-8 min-h-screen">
    <EStoreFetchError
      v-if="error"
      :message="t('fetch_failed', { data: t('articles') })"
      @click="reloaded()"
    />
    <div
      v-else
      class="max-w-4xl mx-auto bg-bg_alt_color rounded-lg shadow-lg p-6"
    >
      <img
        :src="article?.image"
        :alt="article?.title"
        class="rounded-lg mb-6 w-full max-h-64 object-cover"
      />
      <h1 class="text-3xl font-bold mb-4 text-primary_color">
        {{ article?.title }}
      </h1>
      <div v-html="article?.content"></div>

      <div class="flex items-center justify-between mt-8">
        <span class="text-gray-500">#{{ article?.slug }}</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import type { Article } from '~/composables/useMenuModel'
const { t } = useI18n()
const { url } = useDomainHost()
const { store } = useDomainState()
const { articleModel } = useMenuModel()
const route = useRoute()
const articleShowRoute = getAllRoutes().articlesRoutes.getShowArticle(
  store.value?.id,
  route.params.id
)
const { data: article, error } = await useBasicFetch(articleShowRoute, {
  query: {
    domain: useDomainHeader()
  },
  transform: (data: SuccessResponse<Article>) => {
    return articleModel(data.data)
  }
})
function reloaded() {
  window.location.reload()
}
useServerSeoMeta(
  useOgMeta(
    url + '/articles/' + article.value?.slug,
    article.value?.meta_title ?? article.value?.title,
    article.value?.meta_description ?? article.value?.summary,
    article.value?.thumb
  )
)
useHead({
  title: article.value?.meta_title ?? article.value?.title,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: article.value?.meta_description ?? article.value?.summary
    },
    {
      hid: 'keywords',
      name: 'keywords',
      content: article.value?.meta_keywords.join(',')
    }
  ],
  link: [useSeoCanonical(url + '/articles/' + article.value?.slug)],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, store.value?.name, url),
      useSeoBreadcrumbItem(
        2,
        article.value?.title,
        url + '/articles/' + article.value?.slug
      )
    ])
  ]
})
</script>
